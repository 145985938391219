<template>
  <p>
    <strong>Как выдавать курсы и тесты?</strong> &ndash;
    курсы и тесты можно выдавать только через группы.
    По умолчанию у компании есть только одна группа -  &laquo;Все сотрудники&raquo;.
    Чтобы распределить доступные квоты на тесты и/или курсы,
    необходимо зайти во вкладку нужного продукта в меню слева, выбрать группу и сотрудников из списка участников группы, нажать кнопку &laquo;Выдать доступ&raquo; на панели управления,
    расположенной под списком участников, а затем подтвердить выдачу доступа в открывшемся окне, нажав кнопку &laquo;Выслать приглашение&raquo;.
  </p>
  <p>
    <strong>Как создавать группы?</strong> &ndash; для создания новой группы необходимо нажать
    кнопку &laquo;+ группа&raquo; под списком групп в меню слева.
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/81a/81a6026eb1b597d95078a4dcb0d8f16c/3d461170c87848ab5ef1228cd88e90ce" controls></video>

  <p>
    <strong>Как добавить сотрудников в группу?</strong> &ndash; это можно
    сделать, загрузив список сотрудников через excel, или добавить сотрудников
    вручную, нажав кнопку &laquo;Редактировать&raquo; внутри группы. Чтобы создать нового сотрудника,
    необходимо нажать кнопку &laquo;Добавить сотрудника&raquo; на панели управления, расположенной под списком участников группы,
    или &laquo;Создать сотрудника&raquo; на экране редактирования группы.
    Добавленные пользователи автоматически
    получают на свою почту письма с регистрационными данными, по которым они
    смогут зайти в свои личные кабинеты.
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/c19/c19a1479ed77c79c3539350a5e7f8cc2/c9825c45644bf903167b56983416e9c9" controls></video>

  <p>
    <strong>Где проходить курсы?</strong> &ndash; на Главной странице в разделе &laquo;Мои курсы&raquo; или выбрать
    вкладку нужного курса в меню слева (вкладка видна, если у вас есть активные доступы к курсам) и
    нажать кнопку &laquo;Пройти&raquo; напротив имени в списке участников группы.
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/9d9/9d93e9b5af2cc63488f82d28348db59b/186669c4ee80336137d2e9d88cd41d59" controls></video>

  <p>
    <strong>Что такое квоты на тестирование, на курсы?</strong> &ndash; единица
    товара, предоставляющая доступ одному пользователю пройти тестирование, если
    квота на тест (отчёт), или пройти курс (если квота на курс). Работа с квотами
    осуществляется только в группах. Квоту на тестирование или на курс можно забрать у
    сотрудника и передать другому, только если первый сотрудник не приступал к
    прохождению теста/курса.
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/3b9/3b9e8ddf974011c8377b522b3b468ec3/f109efebbe1a87d323082f38af529389" controls></video>

  <p>
    <strong>Где посмотреть результаты тестирования сотрудников?</strong> &ndash;
    Результаты тестирования представлены в форме отчёта. Отчёты с результатами сотрудников
    находятся в группе. У сотрудников, прошедших тестирование, появляется значок <i class="fas fa-box-archive"></i>,
    при нажатии на который открывается окошко со списком дат пройденных тестирований.
    При клике на дату начнётся скачивание отчёта с результатами данного сотрудника.
    Скачать отчёты для всей группы можно с помощью кнопки &laquo;Скачать все отчёты&raquo;.
  </p>

  <video class="video_instruction" src="https://lms.way2wei.ru/upload/disk/e2c/e2cd310e821031423ef4047095fb3439/60857a03de0e3f936bd2a2954494f138" controls></video>

  <p>
    <strong>Где посмотреть аналитику?</strong> &ndash;
    аналитика доступна во вкладке групп и позволяет сопоставить средние результаты по
    тестированию групп между собой, наблюдать динамику изменений уровня
    эмоционального интеллекта, а также оценивать эффективность обучения и
    внедрения среды путем сравнения результатов тестирования &laquo;до&raquo; и
    &laquo;после&raquo; развивающих программ. Можно загружать эталонные
    ЭИ-профили идеальных сотрудников на ту или иную должность и смотреть, кто
    полностью соответствует, у кого есть потенциал к росту, а кто может быть
    более эффективным на другой позиции.
  </p>
  <p>
    <strong>Где посмотреть заказы?</strong> &ndash;
    для этого необходимо навести курсор на имя профиля в правом верхнем углу и выбрать
    в выпадающем списке пункт <a href="/orders">&laquo;Заказы&raquo;</a>. Заказы могут делать только компании для использования продуктов в
    пределах компании или частные лица для личного использования.
  </p>
  <p>
    <strong>Где настроить уведомления о тестировании?</strong> &ndash;
    для этого необходимо навести курсор на имя профиля в правом верхнем углу и выбрать
    в выпадающем списке пункт <a href="/notifications/tests">&laquo;Уведомления&raquo;</a>.
  </p>

  <h3>Вкладки</h3>
  <p>
    <i class="fad fa-cube"></i>
    <strong>Главная</strong> &ndash; главная страница личного кабинета, здесь можно
    начать тестирование, посмотреть отчёт, сформированный по результатам теста, и доступные для
    прохождения курсы.
  </p>
  <p>
    <img src="@/assets/images/icons/report.svg" alt=""/>
    <strong>Тестирования</strong> &ndash; доступные тестирования ЭИ, после прохождения будет сформирован отчёт. Для
    прохождения тестирования необходимо приобрести отчёт (Стандартный, стартовый
    или полный).
  </p>
  <p>
    <img src="@/assets/images/icons/course.svg" alt=""/>
    <strong>Курсы</strong> &ndash; доступные для прохождения курсы.
  </p>
  <p>
    <img src="@/assets/images/icons/references.svg" alt=""/>
    <strong>Специальные продукты</strong> &ndash; доступные специальные продукты: Траектории и Эталонный профиль.
  </p>

  <h3>Статусы тестирования</h3>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock1.svg" />
    <div>
      &ndash; <strong>«Тест не выдан»</strong> &ndash; тест пользователю не назначен.
    </div>
  </div>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock2.svg" />
    <div>
      &ndash; <strong>&laquo;Тест не пройден&raquo;</strong> &ndash; пользователю выдан доступ к
      тесту, но он ещё не приступал к его прохождению. (На
      этом этапе можно забрать квоту на тестирование у данного пользователя
      и передать её другому).
    </div>
  </div>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock3.svg" />
    <div>
      &ndash; <strong>&laquo;В процессе прохождения&raquo;</strong> &ndash; пользователю выдан доступ к
      тестированию, и он приступил к нему. (На этом этапе нельзя
      забрать квоту на тестирование у данного пользователя и передать её
      кому-либо другому).
    </div>
  </div>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock4.svg" />
    <div>
      &ndash; <strong>&laquo;Тест завершён&raquo;</strong> &ndash; пользователь полностью прошёл
      тестирование, по его результатам сформирован отчёт.
    </div>
  </div>

  <h3>Статусы обучения</h3>
  <div class="img-description">
    <i class="fal fa-clipboard"></i>
    <div>&ndash; Нет назначенных курсов или тренажёров.</div>
  </div>
  <div class="img-description">
    <i class="fal fa-clipboard-check"></i>
    <div>&ndash; Назначены курсы или тренажёры.</div>
  </div>

  <h3>Работа с сотрудниками</h3>
  <a
    class="btn btn-accent staff-link"
    :href="`${url}/uploads/pdf/course_instruction.pdf`"
    target="_blank"
    >Скачать инструкцию для работы с сотрудниками</a
  >
</template>

<script>
export default {
  name: "adminFAQ",
  setup() {
    const url = process.env.VUE_APP_FRONT;

    return { url };
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/faq";

.fad.fa-cube {
  margin-right: 5px;
}
</style>